@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("titillium-web-latin-ext-300-normal.6f308548.woff2") format("woff2"), url("titillium-web-all-300-normal.29068b17.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("titillium-web-latin-300-normal.a13afa2c.woff2") format("woff2"), url("titillium-web-all-300-normal.29068b17.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("titillium-web-latin-ext-400-normal.0568c0a4.woff2") format("woff2"), url("titillium-web-all-400-normal.2219445a.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("titillium-web-latin-400-normal.1ed98299.woff2") format("woff2"), url("titillium-web-all-400-normal.2219445a.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("titillium-web-latin-ext-600-normal.62ef4c50.woff2") format("woff2"), url("titillium-web-all-600-normal.15129f6c.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("titillium-web-latin-600-normal.ce83c8c7.woff2") format("woff2"), url("titillium-web-all-600-normal.15129f6c.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("titillium-web-latin-ext-700-normal.068f2ffe.woff2") format("woff2"), url("titillium-web-all-700-normal.91d23b10.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Titillium Web;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("titillium-web-latin-700-normal.2e4ae4e2.woff2") format("woff2"), url("titillium-web-all-700-normal.91d23b10.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-ext-400-normal.e4c4eb1c.woff2") format("woff2"), url("dm-mono-all-400-normal.a28da001.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("dm-mono-latin-400-normal.b942a387.woff2") format("woff2"), url("dm-mono-all-400-normal.a28da001.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.2d1aec40.css.map */
